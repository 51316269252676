import iFrameResizer from "iframe-resizer/js/iframeResizer";
const widgetList = [];

let baseUrl = "https://rechner.schuldnerberatung.de/";

if (process.env.NODE_ENV === "development") {
  baseUrl = "http://localhost:1234/";
}

function widget() {
  const rechner = document.querySelectorAll("pfaendung-rechner");
  if (rechner.length === 0) {
    return false;
  }

  for (let i = 0; i < rechner.length; i++) {
    const publisherId = encodeURIComponent(rechner[i].getAttribute("data-publisher-id") || "0");
    const backgroundColor = encodeURIComponent(rechner[i].getAttribute("data-background-color") || "#ffffff");
    const textColor = encodeURIComponent(rechner[i].getAttribute("data-text-color") || "#313c45");
    const buttonBackgroundColor = encodeURIComponent(rechner[i].getAttribute("data-button-background-color") || "#3889cf");
    const buttonTextColor = encodeURIComponent(rechner[i].getAttribute("data-button-text-color") || "#ffffff");

    const url = `${baseUrl}index.html?publisherId=${publisherId}&backgroundColor=${backgroundColor}&textColor=${textColor}&buttonBackgroundColor=${buttonBackgroundColor}&buttonTextColor=${buttonTextColor}`;

    widgetList[i] = document.createElement("iframe");
    widgetList[i].title = "Pfändungsrechner";
    widgetList[i].ariaLabel = "Pfändungsrechner";
    widgetList[i].setAttribute("class", "pfaendungsrechnerIframe");
    widgetList[i].setAttribute("width", "100%");
    widgetList[i].setAttribute("height", "400px");
    widgetList[i].setAttribute("frameBorder", "0");
    widgetList[i].setAttribute("src", encodeURI(url));

    rechner[i].appendChild(widgetList[i]);
    window.iFrameResize({ checkOrigin: false, log: false }, widgetList[i]);
  }
}

function resize(event) {
  const isLinkOrButton = event.target.nodeName === "A" || event.target.nodeName === "BUTTON";
  if (!isLinkOrButton) {
    return;
  }

  for (let i = 0; i < widgetList.length; i++) {
    if (widgetList[i].offsetHeight === 0) {
      widgetList[i].iFrameResizer.resize();
    }
  }
}

export { baseUrl, widget, resize, iFrameResizer };
